<template>
  <RouterView />
</template>

<style lang="scss">
* {
  user-select: none;
}

body {
  margin: 0;
  padding: 0;
  // font-family: "Microsoft YaHei", "微软雅黑", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: linear-gradient(45deg, #EECFBA, #C5DDE8);
}
</style>
