<template>
  <div class="container">
    <main>
      <p>白茶清欢无别事，我在等风也等你。</p>
      <p>苦酒折柳今相离，无风无月也无你。</p>
      <p>三里清风三里地，步步风里再无你。</p>
      <p>清酒独酌了无趣，醉里梦花夜梦你。</p>
    </main>
    <footer>
      &copy; 2023 gxmcloud.com 版权所有 <a href="https://beian.miit.gov.cn/">湘ICP备2023022802号-1</a>
    </footer>

  </div>
</template>
<style lang="scss" scoped>
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  main {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;

    p {
      display: inline-block;
      text-align: center;
      color: #666;
      font-size: 2rem;
      line-height: 1.5;
      text-shadow: 0 1px 0 #000;
    }

    @media screen and (max-width: 768px) {
      p {
        font-size: 1.5rem;
      }
    }

    @media screen and (max-width: 480px) {
      p {
        font-size: 1rem;
      }
    }
  }

  footer {
    flex-shrink: 0;
    padding: 20px;
    font-size: 14px;
    color: #999999;

    @media screen and (max-width: 768px) {
      padding: 10px;
      font-size: 12px;
    }

    @media screen and (max-width: 480px) {
      font-size: 10px;
    }

    a {
      text-decoration: none;
      color: #666666;
    }
  }
}
</style>